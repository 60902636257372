import imgWischmeyer from "~/assets/landingpage/testimonials/prof-wischmeyer.webp";
import imgGolla from "~/assets/landingpage/testimonials/prof-golla.webp";
import imgHerberger from "~/assets/landingpage/testimonials/prof-herberger.webp";

export const intro_page = {
  hero_section: {
    main_title: "Dein personalisierbares\nOnline-Gesetzbuch.",
    beta_label: "BETA",
    slogan: "Jura. Einfach. Digital.",
    use_for_free: "Kostenlos nutzen!",
    preview_element: {
      law_side: {
        title: "Gesetz.",
        description:
          "{emphasized} findest du die Gesetzesnormen und personalisierst sie:",
        emphasized: "Links",
        features: {
          marking: "Markieren",
          linking: "Verlinken",
          bookmarking: "Bookmarken",
          more: "u.v.m.",
        },
      },
      knowledge_side: {
        title: "Wissen.",
        description:
          "{emphasized} findest du das dazugehörige Wissen in Form von:",
        emphasized: "Rechts",
        features: {
          notes: "Notizen",
          own_schemata: "Eigenen Schemata",
          lexmea_schemata: "LexMea-Schemata",
          more: "u.v.m.",
        },
      },
    },
  },
  features_section: {
    title: "Funktionen.",
    description: "Personalisiere dein Gesetzbuch.",
    first_feature: {
      title: "Unterstreichen, Markieren & Verlinken",
      description:
        "Erkenne auf einen Blick, wo etwas Wichtiges in den Normen steckt!",
    },
    second_feature: {
      title: "Notizen und Definitionen anlegen",
      description:
        "Mache deine Notizen direkt am Gesetz: Anmerkungen, Definitionen und alles was du dir schnell merken willst!",
    },
    third_feature: {
      title: "Schemata ansehen oder eigene erstellen",
      description:
        "Sieh dir zu allen wichtigen Normen Schemata von LexMea an oder verfasse deine eigenen!",
    },
  },
  advantages_section: {
    title: "Vorteile.",
    description: "Nutze die Vorteile der Digitalisierung.",
    first_feature: {
      title: "Verknüpfe dein Wissen",
      description: "durch die dauerhafte Speicherung an der passenden Norm.",
    },
    second_feature: {
      title: "Finde alles",
      description:
        "dank digitaler Speicherung und zentraler Suche für Gesetze, Schemata und Notizen",
    },
    third_feature: {
      title: "Studiere nachhaltig",
      description:
        "und verzichte auf ⌀ 35.000 Seiten* für analoge Gesetze und Nachlieferungen",
    },
  },
  testimonials_section: {
    title: "Meinungen.",
    description: "Was andere über LexMea sagen:",
    testimonials: [
      {
        text: "Zentral für eine gute Klausurlösung ist die Verknüpfung von Norm und Wissen. Dabei hilft LexMea.",
        author: "Prof. Dr. Thomas Wischmeyer",
        picture_attribution: "Marzena Skubatz",
        picture: imgWischmeyer,
        position:
          "Professor für Öffentliches Recht und Recht der Digitalisierung",
      },
      {
        text: "Eine beeindruckende Plattform. LexMea ist Gesetzessammlung und Karteikartenkasten für das digitale Jura-Zeitalter.",
        author: "Jun.-Prof. Dr. Sebastian Golla",
        picture_attribution: "RUB, Marquard",
        picture: imgGolla,
        position:
          "Juniorprofessur für Kriminologie, Strafrecht und Sicherheitsforschung im digitalen Zeitalter Ruhr-Universität Bochum",
      },
      {
        text: "Ein vollständig digitalisiertes Examen setzt die Arbeit mit digitalen Gesetzestexten voraus. LexMea ermöglicht dies.",
        author: "Prof. Dr. Marie Herberger",
        picture_attribution: "Yaph",
        picture: imgHerberger,
        position:
          "Professorin für Bürgerliches Recht, Zivilverfahrensrecht, Recht der Digitalisierung und Methodenlehre Universität Bielefeld",
      },
    ],
  },
  data_ownership_section: {
    title: "Datensouveränität.",
    description: "Wir machen kein Geld mit deinen Daten.",
    data_ownership:
      "Deine {data_ownership_link} verkaufen wir nie!   Die bleiben sicher -  auf europäischen Servern.",
    data_ownership_link: "Daten",
    free_features:
      "Alle Grundfunktionen von LexMea sind für Dich zu jedem Zeitpunkt {free_features_emphasized}",
    free_features_emphasized: "kostenlos.",
    financing:
      "LexMea wird sich langfristig über juristische Stellenanzeigen und Repetitorien finanzieren.",
  },
};
