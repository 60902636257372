import imgWischmeyer from "~/assets/landingpage/testimonials/prof-wischmeyer.webp";
import imgGolla from "~/assets/landingpage/testimonials/prof-golla.webp";
import imgHerberger from "~/assets/landingpage/testimonials/prof-herberger.webp";

export const intro_page = {
  hero_section: {
    main_title: "The everyday tool\n for every lawyer.",
    beta_label: "BETA",
    slogan: "Statutes. Annotations. Knowledge.",
    use_for_free: "Use for free!",
    preview_element: {
      law_side: {
        title: "Law.",
        description:
          "{emphasized} you'll find legal provisions allowing you to:",
        emphasized: "On the left",
        features: {
          marking: "Highlight text",
          bookmarking: "Bookmark provisions",
          linking: "Cross-reference other provisions",
          more: "... and much more",
        },
      },
      knowledge_side: {
        title: "Knowledge.",
        description:
          "{emphasized} you'll find legal content and manage your own knowledge:",
        emphasized: "On the right",
        features: {
          notes: "Notes",
          lexmea_schemata: "LexMea legal content",
          own_schemata: "Your own @.lower:documents",
          more: "... and much more",
        },
      },
    },
  },
  features_section: {
    title: "Features.",
    description: "Personalize your law book.",
    first_feature: {
      title: "Underline, Highlight & Link",
      description: "Instantly find what matters!",
    },
    second_feature: {
      title: "Create Notes and Definitions.",
      description:
        "Make your notes directly on the law: annotations, definitions, and everything you want to remember next time!",
    },
    third_feature: {
      title: "Access LexMea’s Legal Content or Create Your Own.",
      description:
        "We are creating outlines for all important provisions and let you create and share your own! ",
    },
  },
  advantages_section: {
    title: "Advantages.",
    description: "Utilize the benefits of digitalization.",
    first_feature: {
      title: "Link Your Knowledge",
      description: "directly at the relevant provision.",
    },
    second_feature: {
      title: "Find Everything",
      description: "with our search for legal texts, documents and notes.",
    },
    third_feature: {
      title: "Study Sustainably",
      description: "and avoid ⌀ 35,000 pages* for printed laws and updates.",
    },
  },
  testimonials_section: {
    title: "Opinions.",
    description: "What others say about LexMea:",
    testimonials: [
      {
        text: "The link between provisions and knowledge is essential for a successful exam. That's where LexMea helps.",
        author: "Prof. Dr. Thomas Wischmeyer",
        picture_attribution: "Marzena Skubatz",
        picture: imgWischmeyer,
        position: "Professor of Public Law and Law of Digitalization",
      },
      {
        text: "An impressive platform. LexMea is a collection of laws and a card index box for the digital law era.",
        author: "Jun.-Prof. Dr. Sebastian Golla",
        picture_attribution: "RUB, Marquard",
        picture: imgGolla,
        position:
          "Junior Professor of Criminology, Criminal Law and Security Research in the Digital Age, Ruhr University Bochum",
      },
      {
        text: "A fully digitized exam requires working with digital legal texts. LexMea makes this possible.",
        author: "Prof. Dr. Marie Herberger",
        picture_attribution: "Yaph",
        picture: imgHerberger,
        position:
          "Professor of Civil Law, Civil Procedure Law, Digitalization Law and Methodology, University of Bielefeld",
      },
    ],

    wischmeyer_testimonial: {
      picture_attribution: "Photo by Marzena Skubatz",
      text: "The link between provisions and knowledge is essential for a successful exam. That's where LexMea helps.",
      author: "Prof. Dr. Thomas Wischmeyer",
      position: "Professor of Public Law and Law of Digitalization",
    },
  },
  data_ownership_section: {
    title: "Data Sovereignty.",
    description: "We do not make money with your data.",
    data_ownership:
      "We never sell any {data_ownership_link}! They remain secure - on European servers.",
    data_ownership_link: "data",
    free_features:
      "At all times, all basic functions of LexMea will be\n {free_features_emphasized}",
    free_features_emphasized: "free.",
    financing:
      "In the long term, LexMea will be funded through legal job offers and tutorials. ",
  },
};
