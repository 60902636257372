import type { PageContext } from "vike/types";
import FloatingVue from "floating-vue";
import { createUnhead } from "./createUnhead";
import { createSSRApp } from "vue";
import Toast from "vue-toastification";
import { hydrate, QueryClient, VueQueryPlugin } from "@tanstack/vue-query";
import PageShell from "./PageShell.vue";
import { MotionPlugin } from "@vueuse/motion";
import { i18nPlugin } from "~/i18n";
import { createVfm } from "vue-final-modal";
import { provideQueries } from "~/queries";
// import { sentryBrowserConfig } from "../../sentry.browser.config";

const buildQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        // 5 minutes
        staleTime: 5 * 60 * 1000,
        retry: false,
      },
    },
  });
};

export async function createApp(pageContext: PageContext) {
  const rootComponent = ref(markRaw(pageContext.config.Page));
  const PageWithWrapper = defineComponent({
    setup() {
      provideQueries();
    },
    render() {
      return h(
        PageShell,
        {},
        {
          default: () => h(rootComponent.value),
        }
      );
    },
  });

  const app = createSSRApp(PageWithWrapper);
  objectAssign(pageContext, { app });
  const store = createPinia();

  // const { sentryDsn, sentryRelease, environment, apiBaseUrl } =
  //   pageContext.envs;

  // sentryBrowserConfig({
  //   app,
  //   dsn: sentryDsn,
  //   release: sentryRelease,
  //   environment,
  //   apiUrl: apiBaseUrl,
  // });
  const queryClient = buildQueryClient();
  const queryState = pageContext.data?.vueQueryState;
  if (queryState) {
    // hydrate initial state on server in case we fetched something in onBeforeRender
    hydrate(queryClient, queryState);
  }

  const head = createUnhead();

  const i18n = i18nPlugin(pageContext);

  const vfm = createVfm();
  app
    .use(head)
    .use(store)
    .use(VueQueryPlugin, { queryClient })
    .use(Toast, {
      containerClassName: "mt-12",
      toastClassName: "shadow-lg",
      hideProgressBar: true,
      transition: "Vue-Toastification__fade",
      transitionDuration: 150,
    })
    .use(MotionPlugin, {
      directives: {
        "lexmea-slide-top": {
          initial: {
            y: -100,
            opacity: 0,
          },
          visibleOnce: {
            y: 0,
            opacity: 1,
            transition: {
              delay: 225,
              duration: 300,
            },
          },
        },
        "lexmea-slide-left": {
          initial: {
            x: -100,
            opacity: 0,
          },
          visibleOnce: {
            x: 0,
            opacity: 1,
            transition: {
              delay: 225,
              duration: 300,
            },
          },
        },
        "lexmea-slide-right": {
          initial: {
            x: 100,
            opacity: 0,
          },
          visibleOnce: {
            x: 0,
            opacity: 1,
            transition: {
              delay: 225,
              duration: 300,
            },
          },
        },
      },
    })
    .use(FloatingVue, { container: "#teleported" })
    .use(i18n)
    .use(vfm);

  // When doing Client Routing, we mutate pageContext (see usage of `app.changePage()` in `_default.page.client.js`).
  // We therefore use a reactive pageContext.
  const pageContextReactive = reactive(pageContext);

  // We use `app.changePage()` to do Client Routing, see `_default.page.client.js`
  objectAssign(app, {
    changePage: async function (pageContext: PageContext) {
      this.changePageProps.returned = false;
      this.changePageProps.err = null;
      Object.assign(pageContextReactive, pageContext);
      rootComponent.value = markRaw(pageContext.config.Page);
      await nextTick();
      this.changePageProps.returned = true;
      if (this.changePageProps.hardReload) {
        window.location.href = pageContext.urlParsed.href;
      } else if (this.changePageProps.err) {
        throw this.changePageProps.err;
      }
    },
    changePageProps: {
      err: null as unknown,
      returned: false,
      hardReload: false,
    },
  });

  app.config.throwUnhandledErrorInProduction = true;
  app.config.errorHandler = (err_) => {
    console.error(err_);
    if (
      err_ instanceof Error &&
      (err_.message.includes("Failed to fetch dynamically imported module") ||
        err_.message.includes("Importing a module script failed"))
    ) {
      // We need to reload the page as it's now broken
      app.changePageProps.hardReload = true;
    }
    if (app.changePageProps.returned) {
      console.error(err_);
    } else {
      app.changePageProps.err = err_;
    }
  };

  // Make `pageContext` accessible from any Vue component
  setPageContext(app, pageContextReactive);

  return { app, store, head, queryClient };
}

// Same as `Object.assign()` but with type inference
function objectAssign<Obj extends object, ObjAddendum>(
  obj: Obj,
  objAddendum: ObjAddendum
): asserts obj is Obj & ObjAddendum {
  Object.assign(obj, objAddendum);
}
